import React from "react";
import ReactSelect from "react-select";

const customStyles = {
  control: () => ({
    borderRadius: "0.5rem",
    display: "flex",
    border: "1px solid #CDCDD6",
  }),
  placeholder: () => ({
    color: "#d7d7e0",
  }),
  // dropdownIndicator: (provided) => ({
  //   ...provided,
  //   color: "black",
  // }),
  indicatorSeparator: () => ({ display: "none" }),
  // valueContainer: () => ({ paddingLeft: 10, flex: "1" }),
};

export const Select = (props) => {
  return <ReactSelect styles={customStyles} {...props} />;
};
