import axios from "axios";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { AiOutlineCalendar } from "react-icons/ai";
import * as dayjs from "dayjs";
import ReactPaginate from "react-paginate";
import { Spinner } from "./components/Spinner";
import { DateRangePicker } from "react-date-range";
import headerImage from "./images/header-image.svg";
import { Input } from "./components/Input/Input";
import { Select } from "./components/Select/Select";
import { Checkbox } from "pretty-checkbox-react";
import "@djthoms/pretty-checkbox";

const defaultFormValues = {
  applicationNumber: "",
  participant: "",
  publishedOnly: true,
  city: null,
  suburb: null,
  costType: null,
  costAwarded: null,
  startDate: null,
  endDate: new Date(),
};

// https://github.com/atomicpages/pretty-checkbox-react#readme

function App() {
  const { register, handleSubmit, watch, setValue, getValues, reset } = useForm(
    {
      defaultValues: defaultFormValues,
    }
  );

  const [page, setPage] = useState(1);
  const [isSelectingDate, setIsSelectingDate] = useState(false);

  const { data: optionsData } = useQuery("form-options", async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND_API}tenancies/orders/form-options`
    );

    return data;
  });

  const { isLoading, data, refetch } = useQuery(
    ["orders", page, getValues()],
    async ({ queryKey: [key, page, filters] }) => {
      const {
        applicationNumber,
        participant,
        publishedOnly,
        city,
        suburb,
        startDate,
        endDate,
        costAwarded,
        costType,
      } = filters;
      console.log("page", page);
      const ordersQueryUrl = new URL(
        `${process.env.REACT_APP_BACKEND_API}tenancies/orders`
      );

      ordersQueryUrl.searchParams.append("per_page", 50);
      ordersQueryUrl.searchParams.append("page", page);
      ordersQueryUrl.searchParams.append("published_only", publishedOnly);

      city && ordersQueryUrl.searchParams.append("city", city.value);
      suburb && ordersQueryUrl.searchParams.append("suburb", suburb.value);
      costAwarded &&
        ordersQueryUrl.searchParams.append("costAwarded", costAwarded.value);
      costType &&
        ordersQueryUrl.searchParams.append("costType", costType.value);
      startDate &&
        ordersQueryUrl.searchParams.append("startDate", startDate.getTime());
      endDate &&
        ordersQueryUrl.searchParams.append("endDate", endDate.getTime());

      applicationNumber &&
        ordersQueryUrl.searchParams.append(
          "applicationNumber",
          applicationNumber
        );
      participant &&
        ordersQueryUrl.searchParams.append("participant", participant);

      const { data } = await axios(ordersQueryUrl.href);
      return data;
    }
  );

  const onSubmit = (data) => {
    console.log(data.publishedOnly);
    setPage(1);
    refetch();
  };

  const onReset = () => {
    reset({
      ...defaultFormValues,
      ...(optionsData.minDate
        ? { startDate: new Date(optionsData.minDate) }
        : {}),
    });
  };

  // setting startDate if not defined
  useEffect(() => {
    if (optionsData) {
      setValue("startDate", new Date(optionsData.minDate));
    }
  }, [optionsData, setValue]);

  const citiesOptions =
    optionsData?.cities.map(({ id, name }) => ({
      label: name,
      value: id,
    })) ?? [];
  const suburbsOptions =
    optionsData?.suburbs.map(({ id, name }) => ({
      label: name,
      value: id,
    })) ?? [];
  const costTypeOptions =
    optionsData?.costTypes.map((type) => ({
      label: type,
      value: type,
    })) ?? [];
  const costAwardedTypeOptions =
    optionsData?.costAwardedTypes.map((type) => ({
      label: type,
      value: type,
    })) ?? [];

  return (
    <div className="flex flex-col items-center text-blue-dark">
      <img src={headerImage} alt="header" className="max-w-lg w-full mt-6" />
      <h1 className="my-6 text-3xl font-bold ">Search Tenancy Data</h1>

      <form
        onSubmit={handleSubmit(onSubmit)}
        className="grid grid-cols-2 gap-4 max-w-5xl w-full px-6"
      >
        <div className="flex flex-col">
          <label htmlFor="applicationNumber">Application Number</label>
          <Input
            {...register("applicationNumber")}
            placeholder="Application Number"
            className="border-b border-gray-border mb-2"
          />
        </div>
        <div className="flex flex-col">
          <label htmlFor="participant">Participant Search</label>
          <Input
            placeholder="Participant Search"
            {...register("participant")}
            className="border-b border-gray-border mb-2"
          />
        </div>
        <div>
          <label htmlFor="city">City</label>
          <Select
            name="city"
            options={citiesOptions}
            value={watch("city")}
            onChange={(option) => setValue("city", option)}
            placeholder="Select City"
            isClearable
          />
        </div>
        <div>
          <label htmlFor="suburb">Suburb</label>
          <Select
            name="suburb"
            options={suburbsOptions}
            value={watch("suburb")}
            onChange={(option) => setValue("suburb", option)}
            placeholder="Select Suburb"
            isClearable
          />
        </div>
        <div>
          <label htmlFor="costType">Cost Type</label>
          <Select
            name="costType"
            options={costTypeOptions}
            value={watch("costType")}
            onChange={(option) => setValue("costType", option)}
            placeholder="Select Cost Type"
            isClearable
          />
        </div>
        <div>
          <label htmlFor="costAwarded">Cost Awarded</label>
          <Select
            name="costAwarded"
            options={costAwardedTypeOptions}
            value={watch("costAwarded")}
            onChange={(option) => setValue("costAwarded", option)}
            placeholder="Select Cost Awarded"
            isClearable
          />
        </div>
        <div className="col-span-2 relative">
          <label htmlFor="selectDate">Select Date</label>
          <div
            className="border border-gray-border rounded-lg px-4 py-2 flex justify-between items-center cursor-pointer"
            onClick={() => setIsSelectingDate((v) => !v)}
          >
            <div>
              {dayjs(watch("startDate")).format("D MMMM YYYY")} -{" "}
              <span className="text-gray">
                {dayjs(watch("endDate")).format("D MMMM YYYY")}
              </span>
            </div>
            <AiOutlineCalendar size={22} />
          </div>
          {isSelectingDate && (
            <div className="absolute  flex items-center   z-10 w-full flex-col  ">
              <div className="bg-white flex flex-col items-center rounded-lg shadow-2xl">
                <DateRangePicker
                  ranges={[
                    {
                      startDate: watch("startDate") ?? new Date(),
                      endDate: watch("endDate") ?? new Date(),
                      key: "selection",
                    },
                  ]}
                  onChange={({ selection }) => {
                    setValue("startDate", selection.startDate);
                    setValue("endDate", selection.endDate);
                  }}
                  showSelectionPreview
                  moveRangeOnFirstSelection={false}
                  months={2}
                  direction="horizontal"
                  className=""
                  maxDate={new Date()}
                  minDate={new Date(optionsData?.minDate ?? 0)}
                  shownDate={new Date()}
                />
                <div className="py-2 mt-2 border-t border-gray w-full flex justify-center">
                  <div
                    className="px-6 py-2 bg-cyan rounded-lg text-white cursor-pointer"
                    onClick={() => setIsSelectingDate(false)}
                  >
                    Save
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <Checkbox
          {...register("publishedOnly")}
          color="custom-primary"
          className="col-span-2 flex items-center"
          shape="round"
          variant="thick"
        >
          Only Published
        </Checkbox>
        <div className="col-span-2 flex justify-center">
          <button
            onClick={onReset}
            type="reset"
            className="px-6 py-2 bg-gray rounded-lg mr-2"
          >
            Clear
          </button>
          <button
            type="submit"
            className="px-6 py-2 bg-cyan rounded-lg ml-2 text-white"
          >
            Submit
          </button>
        </div>
      </form>
      <div className="max-w-5xl w-full px-6 mt-6 text-center">
        {data?.items.length > 0 && (
          <div className="flex w-full font-b border-b-2 pb-2 mb-2">
            <div className="flex-1">App. Number</div>
            <div className="flex-1">Party Names</div>
            <div className="flex-1">Issue Date</div>
            <div className="w-32">PDF</div>
          </div>
        )}
        {isLoading && <Spinner />}
        {data?.items.length === 0 && (
          <div className="my-12 text-3xl font-bold text-center w-full">
            No Orders Found
          </div>
        )}
        {data?.items.map((order) => {
          return (
            <div key={order.id} className="flex border-b-2 pb-2 mb-2">
              <div className="flex-1">{order.applicationNumber}</div>
              <div className="flex-1">
                {order.parties?.map((party) => (
                  <div key={party.id}>{party.name}</div>
                ))}
              </div>
              <div className="flex-1">
                {dayjs(order.publishedDate).format(`DD/MM/YYYY`)}
              </div>
              <div className="self-center px-6 py-2 w-32 bg-cyan rounded-lg text-white">
                <a target="_blank" rel="noreferrer" href={order.pdf}>
                  Download
                </a>
              </div>
            </div>
          );
        })}
        {data?.items.length > 0 && (
          <ReactPaginate
            forcePage={page - 1}
            page={page - 1}
            pageCount={data?.pagination.totalPages}
            containerClassName="flex justify-center container mx-auto my-4"
            activeClassName="font-bold"
            pageClassName="px-4 cursor-pointer"
            onPageChange={({ selected }) => {
              setPage(selected + 1);
              refetch();
            }}
          />
        )}
      </div>
    </div>
  );
}

export default App;
