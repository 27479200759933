import classNames from "classnames";
import React from "react";

export const Input = React.forwardRef((props, ref) => {
  return (
    <input
      {...props}
      ref={ref}
      className={classNames(
        "border border-gray-border rounded-lg mb-2 px-4 py-2",
        props.className
      )}
    />
  );
});
